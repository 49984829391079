import KeyValueTableFilter, { KeyValueRecord } from "components/KeyValueTableFilter";
import { ClearTableFilterRequest } from "models/common";
import { roleNameToDisplayName } from "util/helpers";
import { ExtendedFilterDropdownProps } from "util/table";

const data: KeyValueRecord = {
    [roleNameToDisplayName("admin")]: "admin",
    [roleNameToDisplayName("staff")]: "staff",
    [roleNameToDisplayName("typer")]: "typer",
    [roleNameToDisplayName("user")]: "user",
    [roleNameToDisplayName("breaker")]: "breaker",
};

const RolesFilter = ({
    prefixCls,
    visible,
    confirm,
    selectedKeys,
    setSelectedKeys,
    clearFilters,
    onFilter,
}: ExtendedFilterDropdownProps) => {
    return <KeyValueTableFilter
        prefixCls={prefixCls}
        visible={visible}
        confirm={confirm}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearFilters}
        onFilter={onFilter}
        clearTableFilterRequest={ClearTableFilterRequest.Roles}
        keyValuePairs={data}
    />;
}

export default RolesFilter;