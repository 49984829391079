import { CheckCircleFilled, ExclamationCircleFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { Button, Divider, Modal, Spin } from "antd";
import { StaffRoutes } from "appRoutePaths";
import PrintButton from "components/staff/Shipping/PrintButton";
import useShipmentCardNavigation from "hooks/useShipmentCardNavigation";
import useShipmentCardPulling from "hooks/useShipmentCardPulling";
import { CssVariables } from "models/common";
import { ShipmentBatchType, ShipmentCard, ShipmentCardStatus } from "models/shipping";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { centsToDollars, localizedDate } from "util/helpers";

export const StatusIcon: FC<{ status: ShipmentCardStatus }> = ({ status }) => {
    switch (status) {
        case ShipmentCardStatus.Pulled:
            return <CheckCircleFilled style={{ color: CssVariables.darkGreen, padding: "5px" }} />
        case ShipmentCardStatus.NotPulled:
            return <ExclamationCircleFilled style={{ color: CssVariables.warning, padding: "5px" }} />
        default:
            return null;
    }
}

const CallOutItem: FC<{ label: string, value: string | React.ReactNode }> = ({ label, value }) => {
    return <VStack alignItems='start'>
        <Text fontSize={14}>{label}</Text>
        <Text style={{ marginTop: 0 }} as="div">{value}</Text>
    </VStack>;
}

const ShipmentBatchCards = () => {
    const { shipmentBatchId } = useParams();
    const [cardId, setCardId] = useState<string | undefined | null>();
    const { isLoading, data, refetch } = useShipmentCardNavigation(shipmentBatchId!, cardId);
    const { pullCard, skipCard } = useShipmentCardPulling();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading) {
            const { shipmentBatchType, previousCard: prev, currentCard: cur } = data!;

            const isHeavyBulk =
                shipmentBatchType === ShipmentBatchType.HeavyBulkFirstClass ||
                shipmentBatchType === ShipmentBatchType.HeavyBulkPriority;

            if (isHeavyBulk && prev && prev.shipmentId !== cur.shipmentId) {
                Modal.info({
                    title: 'New Order',
                    content: 'This card is the start of a new order. Place a divider!',
                    // race condition where sometimes 2 instances of the modal open.
                    // I'm from buenos aires and I say close 'em all
                    onOk: Modal.destroyAll
                });
            }
        }
    }, [isLoading, data]);

    if (isLoading) {
        return <Box h="80vh" display="flex" justifyContent="center" alignItems="center">
            <Spin size="large" tip="Loading..." />
        </Box>;
    }

    const {
        hasPreviousCard,
        hasNextCard,
        previousCard,
        shipmentBatchName,
        shipmentNumber,
        shipmentCount,
        itemNumber,
        itemCount,
        currentCard,
        nextCard,
        nextUntouchedCardId
    } = data!;

    const touchAndMoveNext = async (func: (card: ShipmentCard) => Promise<boolean>) => {
        const ok = await func(currentCard);

        if (ok && hasNextCard) {
            setCardId(nextCard!.cardId);
        } else if (ok && !hasNextCard) {
            refetch();
            Modal.success({
                title: `${shipmentBatchName} Complete`,
                content: 'You have finished pulling this batch.',
                onOk: () => navigate(StaffRoutes.shipmentBatches.url)
            });
        }
    }

    return <Box p={50}>
        <VStack>
            <Box justifyContent='space-between' display='flex' style={{ width: '100%' }}>
                <VStack alignItems='start'>
                    <Box fontSize={20} alignSelf='center'>{shipmentBatchName}</Box>
                    <Button style={{ padding: 0 }} type="link" href={StaffRoutes.shipmentBatches.url}>&#8592;Back to Shipment Batches</Button>
                </VStack>
                <Box>
                    <HStack>
                        <PrintButton />
                        <VStack>
                            <Text fontSize={20}>Order {shipmentNumber} of {shipmentCount}</Text>
                            <Text fontSize={20}>Item {itemNumber} of {itemCount}</Text>
                        </VStack>
                    </HStack>
                </Box>
            </Box>
            <Divider />
            <Box>
                <Box fontSize={30} py={2} fontWeight="bold">{currentCard.title}</Box>
                <Box py={2}>
                    <HStack fontSize={30} justifyContent='space-between' w='100%'>
                        <CallOutItem label='Original Upload Date' value={localizedDate(currentCard.originalUploadDate)} />
                        <CallOutItem label='Location' value={
                            <Text fontWeight='bold'>
                                {!currentCard.validSKU && <ExclamationCircleOutlined style={{ color: CssVariables.errorRed, paddingRight: '5px' }} />}
                                {currentCard.location} <StatusIcon status={currentCard.status} />
                            </Text>
                        } />
                        <CallOutItem label='Sale Price' value={centsToDollars(currentCard.salePriceInCents)} />
                    </HStack>
                </Box>
                <HStack display='flex' justifyContent='center'>
                    {currentCard.frontImage && <Image src={currentCard.frontImage} maxHeight='400px' />}
                    {currentCard.backImage && <Image src={currentCard.backImage} maxHeight='400px' />}
                </HStack>
            </Box>
            <Box>
                <Button
                    disabled={!hasPreviousCard}
                    size="large"
                    style={{ margin: '5px' }}
                    title={previousCard?.title || "No Previous Card"}
                    type="primary" onClick={() => setCardId(previousCard!.cardId)}>
                    Previous
                </Button>

                <Button
                    size="large"
                    style={{ margin: '5px' }}
                    title={nextCard?.title || "No Next Card"}
                    onClick={() => setCardId(nextCard!.cardId)}
                    disabled={!hasNextCard}
                >
                    Skip Card
                </Button>

                <Button
                    size="large"
                    style={{ margin: '5px' }}
                    title={nextCard?.title || "No Next Card"}
                    danger
                    type="primary" onClick={() => touchAndMoveNext(skipCard)}
                >
                    Not Pulled{hasNextCard ? ", Next" : ""}
                </Button>

                <Button
                    size="large"
                    style={{ margin: '5px' }}
                    title={nextCard?.title || "No Next Card"}
                    type="primary" onClick={() => touchAndMoveNext(pullCard)}
                >
                    Pulled{hasNextCard ? ", Next" : ""}
                </Button>

                {nextCard?.cardId !== nextUntouchedCardId && <Button
                    size="large"
                    style={{ margin: '5px' }}
                    title={nextUntouchedCardId ? "Next card that needs to be pulled" : "All cards have been pulled or marked as missing"}
                    onClick={() => setCardId(nextUntouchedCardId)}
                    disabled={!nextUntouchedCardId}
                >
                    Jump to Next Skipped Card
                </Button>}
            </Box>
        </VStack >
    </Box >;
}

export default ShipmentBatchCards;