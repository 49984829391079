import { ApplicationUser } from "models/applicationUsers";
import { useAuthenticatedRequestCreator } from "./useRequests";

const ACCOUNT_ROOT = "/account";

export type UserSearchOption = {
    name: string;
    includeInactive?: boolean;
    roles?: string[];
}

export const useApplicationUsers = () => {
    const findUsers = useAuthenticatedRequestCreator<ApplicationUser[], UserSearchOption>(
        opt => {
            const { name, includeInactive, roles = [] } = opt;

            const inAnyGivenRole = roles.length > 0
                ? `&${roles.map(r => `inRole=${r}`).join('&')}`
                : '';

            return {
                method: "get",
                url: `${ACCOUNT_ROOT}/customers/?name=${name}&allUsers=${!!includeInactive}${inAnyGivenRole}`
            };
        }
    );

    return { findUsers };
};
