import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { Button, Divider, Modal, Spin } from "antd";
import { StaffRoutes } from "appRoutePaths";
import PrintButton from "components/staff/Shipping/PrintButton";
import CallOutItem from "components/staff/UnsoldCards/CalloutItem";
import StatusIcon from "components/staff/UnsoldCards/StatusIcon";
import useUnsoldCardNavigation from "hooks/useUnsoldCardNavigation";
import useUnsoldCardPulling from "hooks/useUnsoldCardPulling";
import { CssVariables } from "models/common";
import { UnsoldCard } from "models/unsoldCards";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { localizedDate } from "util/helpers";

const UnsoldCards = () => {
    const { unsoldCardBatchId } = useParams();
    const [cardId, setCardId] = useState<string | undefined | null>();
    const { isLoading, data, refetch } = useUnsoldCardNavigation(unsoldCardBatchId!, cardId);
    const { pullCard, skipCard } = useUnsoldCardPulling();
    const navigate = useNavigate();

    if (isLoading) {
        return <Box h="80vh" display="flex" justifyContent="center" alignItems="center">
            <Spin size="large" tip="Loading..." />
        </Box>;
    }

    const {
        hasPreviousCard,
        hasNextCard,
        previousCard,
        unsoldCardBatchName,
        itemNumber,
        itemCount,
        currentCard,
        nextCard,
        nextUntouchedCardId
    } = data!;

    const touchAndMoveNext = async (func: (card: UnsoldCard) => Promise<boolean>) => {
        const ok = await func(currentCard);

        if (ok && hasNextCard) {
            setCardId(nextCard!.cardId);
        } else if (ok && !hasNextCard) {
            refetch();
            Modal.success({
                title: `${unsoldCardBatchName} Complete`,
                content: 'You have finished pulling this batch.',
                onOk: () => navigate(StaffRoutes.unsoldCardBatches.url)
            });
        }
    }

    return <Box p={50}>
        <VStack>
            <Box justifyContent='space-between' display='flex' style={{ width: '100%' }}>
                <VStack alignItems='start'>
                    <Box fontSize={20} alignSelf='center'>{unsoldCardBatchName}</Box>
                    <Button style={{ padding: 0 }} type="link" href={StaffRoutes.unsoldCardBatches.url}>&#8592;Back to Unsold Card Batches</Button>
                </VStack>
                <Box>
                    <HStack>
                        <PrintButton />
                        <VStack>
                            <Text fontSize={20}>Item {itemNumber} of {itemCount}</Text>
                        </VStack>
                    </HStack>
                </Box>
            </Box>
            <Divider />
            <Box>
                <Box fontSize={30} py={2} fontWeight="bold">{currentCard.title}</Box>
                <Box py={2}>
                    <HStack fontSize={30} justifyContent='space-between' w='100%'>
                        <CallOutItem label='Original Upload Date' value={localizedDate(currentCard.originalUploadDate)} />
                        <CallOutItem label='Location' value={
                            <Text fontWeight='bold'>
                                {!currentCard.validSKU && <ExclamationCircleOutlined style={{ color: CssVariables.errorRed, paddingRight: '5px' }} />}
                                {currentCard.location} <StatusIcon status={currentCard.status} />
                            </Text>
                        } />
                    </HStack>
                </Box>
                <HStack display='flex' justifyContent='center'>
                    {currentCard.frontImage && <Image src={currentCard.frontImage} maxHeight='400px' />}
                    {currentCard.backImage && <Image src={currentCard.backImage} maxHeight='400px' />}
                </HStack>
            </Box>
            <Box>
                <Button
                    disabled={!hasPreviousCard}
                    size="large"
                    style={{ margin: '5px' }}
                    title={previousCard?.title || "No Previous Card"}
                    type="primary" onClick={() => setCardId(previousCard!.cardId)}>
                    Previous
                </Button>

                <Button
                    size="large"
                    style={{ margin: '5px' }}
                    title={nextCard?.title || "No Next Card"}
                    onClick={() => setCardId(nextCard!.cardId)}
                    disabled={!hasNextCard}
                >
                    Skip Card
                </Button>

                <Button
                    size="large"
                    style={{ margin: '5px' }}
                    title={nextCard?.title || "No Next Card"}
                    danger
                    type="primary" onClick={() => touchAndMoveNext(skipCard)}
                >
                    Not Pulled{hasNextCard ? ", Next" : ""}
                </Button>

                <Button
                    size="large"
                    style={{ margin: '5px' }}
                    title={nextCard?.title || "No Next Card"}
                    type="primary" onClick={() => touchAndMoveNext(pullCard)}
                >
                    Pulled{hasNextCard ? ", Next" : ""}
                </Button>

                {nextCard?.cardId !== nextUntouchedCardId && <Button
                    size="large"
                    style={{ margin: '5px' }}
                    title={nextUntouchedCardId ? "Next card that needs to be pulled" : "All cards have been pulled or marked as missing"}
                    onClick={() => setCardId(nextUntouchedCardId)}
                    disabled={!nextUntouchedCardId}
                >
                    Jump to Next Skipped Card
                </Button>}
            </Box>
        </VStack >
    </Box >;
};

export default UnsoldCards;