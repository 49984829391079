import { CardBatch } from "./cards";
import { DayOfWeek } from "./common";

export const CorePackageTypeIds = {
    Standard: '07002f97-4794-44d0-b987-c890e0bccf50',
    Under20: 'e6fdbb1a-5764-41e0-b18f-0b6b73c0511d',
    Wax: 'fb6e88e3-76cc-4c73-bf82-e7e2b487c4da',
    Legacy: 'c3ebd261-3a97-4ab2-9ab3-f086c65aa18c',
    Premium: 'd152aaef-c698-478c-b807-6e5676e2b96c'
} as const;

export enum PackageStatus {
    Unknown = 0,
    Ebay = 1,
    Pending = 2,
    Received = 3,
    Returned = 4,
}

export enum PackageAmount {
    NotApplicable = -1,
    Unknown = 0,
    QuarterRow = 1,
    HalfRow = 2,
    One = 3,
    Two = 4,
    Three = 5,
    Four = 6,
    Five = 7,
    Six = 8,
    Seven = 9,
    Eight = 10,
    Nine = 11,
    Ten = 12,
    Eleven = 13,
    Twelve = 14,
    Thirteen = 15,
    Fourteen = 16,
    Fifteen = 17,
    Sixteen = 18,
    Seventeen = 19,
    Eighteen = 20,
    Nineteen = 21,
    Twenty = 22,
    OverTwenty = 23,
    Under20 = 24,
    Wax = 25
}

export enum FeeTier {
    Unknown = 0,
    Unsold = 1,
    Tier1 = 2,
    Tier2 = 3,
    Tier3 = 4,
    Tier4 = 5,
    Tier5 = 6,
    Tier6 = 7
}

export enum PackageTypeWeight {
    Unknown = 0,
    Premium = 100,
    Priority = 200,
    Standard = 300
}

export const FeeTierNames: Record<FeeTier, string> = {
    [FeeTier.Unsold]: "Unsold",
    [FeeTier.Tier1]: "Fee Tier 1",
    [FeeTier.Tier2]: "Fee Tier 2",
    [FeeTier.Tier3]: "Fee Tier 3",
    [FeeTier.Tier4]: "Fee Tier 4",
    [FeeTier.Tier5]: "Fee Tier 5",
    [FeeTier.Tier6]: "Fee Tier 6",
    [FeeTier.Unknown]: "Unknown",
};

export const FeeTierRange: Record<FeeTier, string> = {
    [FeeTier.Unsold]: "$0.00",
    [FeeTier.Tier1]: "$0.99 - $9.99",
    [FeeTier.Tier2]: "$10.00 - $24.99",
    [FeeTier.Tier3]: "$25.00 - $499.99",
    [FeeTier.Tier4]: "$500.00 - $999.99",
    [FeeTier.Tier5]: "$1,000 - $4,999.99",
    [FeeTier.Tier6]: ">= $5,000",
    [FeeTier.Unknown]: "N/A",
};

export const PackageTypeWeightNames: Record<PackageTypeWeight, string> = {
    [PackageTypeWeight.Unknown]: "Unknown",
    [PackageTypeWeight.Premium]: "Premium",
    [PackageTypeWeight.Priority]: "Priority",
    [PackageTypeWeight.Standard]: "Standard"
};

export interface PackageType {
    id: string;
    isCoreType: boolean;
    enabled: boolean;
    displayName: string;
    internalName: string;
    leadTimeInDays: number | null;
    estimatedUploadDate: string | null;
    eventDayOfWeek: DayOfWeek | null;
    usesAmounts: boolean;
    bannerOrdinal: number | null;
    processingFeeInCents: number;
    usesLeadTime: boolean;
    tierBasedFees: PackageTypeFees[];
    sortingWeight: PackageTypeWeight;
    supportSilentAccounts: boolean;
}

export interface PackageTypeFees {
    id: string;
    packageTypeId: string;
    feeTier: FeeTier;
    percentageInPoints: number | null;
    flatFeeInCents: number | null;
}

export interface CustomerPackage {
    id: string;
    friendlyId: number;
    packageTypeId: string;
    packageStatus: PackageStatus;
    receivedDate: string;
    estimatedUploadDate: string | null;
    uploadDate: string | null;
    ebayLink: string | null;
    packageTypeDisplayName: string;
    processingFeeInCents: number;
}

export interface StaffPackage extends CustomerPackage {
    packageStatus: PackageStatus;
    packageAmount: PackageAmount;
    customerId: string;
    customerEmail: string;
    customerFirstName: string | undefined;
    customerLastName: string | undefined;
    batches: CardBatch[];
    returnedCards: ReturnedCards[];
    packageTypeInternalName: string;
    priority: boolean;
}

export type ReturnedCards = {
    id: string;
    accountId: string;
    packageId: string;
    labelTransactionId: string;
    labelUrl: string;
    trackingNumber: string;
    shippingCostInCents: string;
    timestamp: string;
};

export type FeeBreakdown = {
    grossAmountInCents: number;
    packageTypeInternalName: string;
    feeTier: FeeTier;
    processingFeeInCents: number;
    percentageInPoints: number | null;
    flatFeeInCents: number | null;
    isDefaultFee: boolean;
    totalFeesInCents: number;
    netToCustomerInCents: number;
    source: string;
};