import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Button, Divider, Spin } from "antd";
import { StaffRoutes } from "appRoutePaths";
import PrintButton from "components/staff/Shipping/PrintButton";
import CallOutItem from "components/staff/UnsoldCards/CalloutItem";
import { useAuthenticatedRequest } from "hooks/useRequests";
import { CssVariables, NoPrintSx } from "models/common";
import { ShipmentBatchMissingCard } from "models/shipping";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { centsToDollars, groupBy } from "util/helpers";

const FullWidthPrintSx = {
    "@media print": {
        width: '100% !important'
    }
};

const ContainerPrintSx = {
    "@media print": {
        display: 'block',
        height: 'auto'
    }
};

const useMissingCards = (shipmentBatchId: string) => {
    const getCards = useAuthenticatedRequest<ShipmentBatchMissingCard[]>({
        method: "get",
        url: `/shipping/batches/${shipmentBatchId}/missing-cards`
    });

    return useQuery(["shipping", "batches", shipmentBatchId, "missing-cards"], getCards);
};

const MissingCard: FC<ShipmentBatchMissingCard> = ({
    shipmentBatchName,
    shipmentFriendlyId,
    sku,
    title,
    frontImageUrl,
    backImageUrl,
    salePriceInCents,
    recipientName
}) => {
    return <Box
        style={{ pageBreakInside: 'avoid', borderBottom: '1px #ddd solid', width: '70%' }}
        sx={FullWidthPrintSx}
        pb={'1px'}
    >
        <Box fontSize={14} fontWeight="bold">{title}</Box>
        <Box py={2}>
            <HStack fontSize={14} justifyContent='space-between' w='100%'>
                <CallOutItem label='Shipment:' value={`#${shipmentFriendlyId}`} />
                <CallOutItem label='Ship To:' value={recipientName} />
                <CallOutItem label='Sale Price:' value={centsToDollars(salePriceInCents)} />
                <CallOutItem label='Batch:' value={shipmentBatchName} />
                <CallOutItem label='SKU:' value={
                    <Text fontWeight='bold'>
                        {!sku && <ExclamationCircleOutlined style={{ color: CssVariables.errorRed, paddingRight: '5px' }} />}
                        {sku ?? "Invalid SKU"}
                    </Text>
                } />
            </HStack>
        </Box>
        <HStack display='flex' justifyContent='center'>
            {frontImageUrl && <Image src={frontImageUrl} w={200} />}
            {backImageUrl && <Image src={backImageUrl} w={200} />}
        </HStack>
    </Box>;
};

const ShipmentBatchMissingCards = () => {
    const { shipmentBatchId } = useParams();
    const { isLoading, data } = useMissingCards(shipmentBatchId!);

    if (isLoading || !data) {
        return <Box h="80vh" display="flex" justifyContent="center" alignItems="center">
            <Spin size="large" tip="Loading..." />
        </Box>;
    }

    const shipments = groupBy(data, x => x.shipmentFriendlyId);

    return <Box p={50}>
        <VStack sx={ContainerPrintSx}>
            <Box justifyContent='space-between' display='flex' style={{ width: '100%' }} sx={NoPrintSx}>
                <VStack alignItems='start'>
                    <Box fontSize={20} alignSelf='center'>Missing Cards - {data[0]?.shipmentBatchName}</Box>
                    <Button style={{ padding: 0 }} type="link" href={StaffRoutes.shipmentBatches.url}>&#8592;Back to Shipment Batches</Button>
                </VStack>
                <Box>
                    <HStack>
                        <PrintButton />
                        <VStack>
                            <Text fontSize={14}>{data.length} Cards across</Text>
                            <Text fontSize={14}>{shipments.size} Shipments</Text>
                        </VStack>
                    </HStack>
                </Box>
            </Box>
            <Divider />
            {Array.from(shipments.values())
                .map((cards, idx) => {
                    return <>
                        {cards.map(c => <MissingCard {...c} />)}
                        {(idx + 1 !== shipments.size) && <div style={{ clear: 'both', pageBreakAfter: 'always' }} />}
                    </>
                })}
        </VStack>
    </Box >;
}

export default ShipmentBatchMissingCards;